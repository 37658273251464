import React, { useEffect, useRef, useContext } from 'react';

import { Grid, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AllIndustryCard } from './AllIndustryCard';
import { CtaCard } from '../../General/CtaCard';
import { Testimonials } from '../../Testimonials';
import { WaveUpSVG } from '../../WaveSVGs/WaveUpSVG';
import IndustrySearchContext from '../../../context/IndustrySearchContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
	noIndustryTitle: {
		color: '#fff',
		fontWeight: 700,
		fontSize: '2rem',
		marginBottom: '0.5rem',
		lineHeight: '1.2',
	},
	noIndustrySubTitle: {
		color: '#fff',
		fontWeight: 500,
		fontSize: '1.1rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '1rem',
		},
	},
	noIndustryCTA: {
		background: theme.lightBlue,
		// opacity: 1,
		transition: 'all 0.35s ease !important',
		// '&:hover': {
		// 	opacity: '0.85 !important',
		// },
	},
	cards: {
		marginTop: '-13rem',
		marginBottom: '3rem',
		//paddingBottom: '5.5em',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-15rem',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '-12rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-6rem',
		},
	},
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '500px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
		[theme.breakpoints.down('xs')]: {
			backgroundPosition: 'left 60px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
	testimonialTitle: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
}));

export const AllIndustryBody = ({
	allIndustries,
	industriesData,
	setFormModalOpen,
}) => {
	const classes = useStyles();
	const formRef = useRef(null);

	const { setDefault, filteredIndustries } = useContext(IndustrySearchContext);

	useEffect(() => {
		setDefault(industriesData);
	}, []);

	const {
		industryCallout,
		title,
		testimonial,
		testimonialBackground,
		testimonialTitle,
	} = allIndustries;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<Container fixed>
				<Grid
					container
					direction='row'
					spacing={4}
					className={classes.cards}
					justifyContent='center'>
					{/* {!filteredIndustries.length && (
						<Grid item xs={12}>
							<Typography
								variant='h6'
								style={{ textAlign: 'center', marginTop: '12rem' }}>
								No results returned
							</Typography>
						</Grid>
					)} */}
					{!!filteredIndustries.length
						? filteredIndustries.map((industry, index) => (
								<AllIndustryCard
									industry={industry.node}
									content={industry.searchParams}
									key={index}
								/>
						  ))
						: null}
				</Grid>
				{industryCallout.ctaHeader ? (
				<CtaCard
					background={industryCallout.ctaBackground}
					header={industryCallout.ctaHeader}
					subheader={industryCallout.ctaSubheader}
					ctaText={industryCallout.ctaText}
					internalLink={industryCallout.internalLink}
				/>
			) : null}
			</Container>
			{testimonial?.length ? (
				<>
					<div
						className={classes.testimonialBackground}
						style={{
							backgroundImage: `url(${testimonialBackground.asset.gatsbyImageData.images.fallback.src})`,
						}}
					/>
					<WaveUpSVG fill='#FFF' />
					<div className={classes.testimonialContainer}>
						<Testimonials
							header={testimonialTitle}
							testimonials={testimonial}
						/>
					</div>
				</>
			) : null}
		</>
	);
};
